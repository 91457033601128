import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {XcoScrollToService} from '@reaze/ng-xava-connect';
import { TrendlineConfiguratorPriceInterface, TrendlineConfiguratorProduct } from '../../trendline-configurator.model';

@Component({
  selector: 'tc-reporting-price-offer',
  templateUrl: './trendline-configurator-reporting-price-offer.component.html',
  styles: []
})
export class TrendlineConfiguratorReportingPriceOfferComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() optional;
  @Input() price: TrendlineConfiguratorPriceInterface;
  condition = {true: 'ja', false: 'nein'};
  constructor(private scrollToService: XcoScrollToService, private product: TrendlineConfiguratorProduct) {
  }

  ngOnInit() {
    this.optional = this.getWithOptionalProperty();
  }

  conditionControl(item, value?: string): boolean {
    if (item.type === 'checkbox-image') {
      return this.form.controls[item.name].value === true;
    } else if (item.type === 'radio-image') {
      return this.form.controls[item.name].value === value;
    }
  }

  getWithOptionalProperty() {
    const array = [];
    this.optional.forEach(data => {
      if (data.hasOwnProperty('formElements')) {
        data.formElements.filter(item => {
          if (item.hasOwnProperty('optional')) {
            array.push(item);
          }
        });
      }
    });
    return array;
  }

  public scrollToFormSection($event) {
    $event.preventDefault();
    if ($event.target.getAttribute('href') === '#') {
      this.scrollToService.scrollTo(document.querySelector('#form-costs'),
          '.header--sticky',
          500);
    }
  }
}
