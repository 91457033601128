import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {XcoApiService, XcoPageObjectService} from '@reaze/ng-xava-connect';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    providers: []
})
export class HeaderComponent implements OnInit {

    navigation: any;
    flyout: any = null;
    location: string;
    breadcrumb = [];
    meta: any;
    next: any;
    previous: any;
    activeMainNavElm: number = null;
    activeMainNavRightElm: number = null;
    startElmActive = null;
    pageType: string;
    isBrowser;
    showHeaderMenu = true;

    @HostListener('window:scroll')
    onWindowScroll() {
        this.headerIsCompact();
    }

    constructor(@Inject(PLATFORM_ID) private platformId,
                private _api: XcoApiService,
                private _router: Router,
                private _pageObject: XcoPageObjectService<any>) {

        this.isBrowser = isPlatformBrowser(platformId);

        _router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.location = this._router.url;
            }
        });

        _pageObject.subscribe(bc => {
            if (this.isBrowser) {
                this.breadcrumb = bc.breadcrumb;
                this.meta = bc.meta;
                this.next = bc.next;
                this.previous = bc.previous;

                if (this.meta.hideHeader !== '1') {
                    this.showHeaderMenu = true;
                }
            }
        });

        this.pageType = ''; // environment.page;

    }

    ngOnInit() {
        this._api.getData(environment.endpoints.nav).subscribe((data) => {
            this.navigation = data;
        });

        this._api.getData(environment.endpoints.flyout).subscribe((data) => {
            this.flyout = data.content_elements;
        });
    }

    checkActiveState(item) {
        if ( (this.startElmActive || this.activeMainNavElm || this.activeMainNavRightElm ) === null && this.isBrowser) {
            if (this.breadcrumb.length > 1) {
                return item.active_state === this.breadcrumb[1].link;
            } else {
                return location.pathname === item.active_state;
            }
        }
    }

    headerIsCompact() {
        return false; // this.isBrowser ? window.pageYOffset >= 250 : false;
    }

    goToWebsite(url: string) {
      window.open(url, '_blank');
    }
}
